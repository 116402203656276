/* eslint-disable */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import Helmet from 'react-helmet';
import { scroller } from 'react-scroll';

import { PAGES, SCROLL_CONFIG } from 'config';
import { FORMAT_GRAPH_DATA, getImageUrl } from 'utilities';
import { Layout } from 'containers';
import {
  TourList,
  ActivityList,
  OtherTypes,
  InstagramList,
} from 'layouts';
import { Banner, Breadcrumbs, Gallery, FooterBanner } from 'components';

const showdown = require('showdown');

const converter = new showdown.Converter();

class DestinationPage extends PureComponent {

  componentDidMount() {
    if (typeof fbq !== 'undefined') {
      this.trackFacebookSearchEvent();
    }
  }

  /**
   * Get the gallery slides
   * @param slideIDs
   * @param videoLink
   * @returns array
   */
  getGallerySlides(slideIDs, videoLink) {
    const { data } = this.props;

    const slides = slideIDs
      .filter(id => id)
      .map(id => ({
        id,
        imageUrl: getImageUrl({
          id,
          images: data.allDestinationImages.edges,
          width: 680,
          height: 420,
        }),
      }));

    // If a video link exists, add it as the first array item
    if (videoLink) {
      slides.unshift({
        id: videoLink,
        videoUrl: videoLink,
      });
    }

    return slides;
  }

  trackFacebookSearchEvent() {
    const { data, pageContext } = this.props;
    const tourList = FORMAT_GRAPH_DATA(data.allTourPages)
      .filter(item => item.destination_ids.includes(pageContext.api_id));
    fbq('track', 'Search', {
      content_type: 'destination',
      content_ids: tourList.map(tour => tour.api_id),
      city: pageContext.name,
      region: pageContext.destination_category,
      country: 'New Zealand'
    });
  }

  /**
   * Render
   */
  render() {
    const { pageContext, data } = this.props;

    // Breadcrumbs
    const breadcrumbs = [
      {
        name: PAGES.DESTINATIONS.TITLE,
        absolute_slug: PAGES.DESTINATIONS.PATH,
      },
      {
        name: pageContext.name,
        absolute_slug: pageContext.absolute_slug,
      },
    ];

    // First gallery slides
    const firstGallerySlides = this.getGallerySlides(
      [
        pageContext.first_sub_header_first_picture_id,
        pageContext.first_sub_header_second_picture_id,
        pageContext.first_sub_header_third_picture_id,
        pageContext.first_sub_header_fourth_picture_id,
        pageContext.first_sub_header_fifth_picture_id,
      ],
      pageContext.first_sub_header_video_link,
    );

    // Second gallery slides
    const secondGallerySlides = this.getGallerySlides(
      [
        pageContext.second_sub_header_first_picture_id,
        pageContext.second_sub_header_second_picture_id,
        pageContext.second_sub_header_third_picture_id,
        pageContext.second_sub_header_fourth_picture_id,
        pageContext.second_sub_header_fifth_picture_id,
      ],
      pageContext.second_sub_header_video_link,
    );

    // Third gallery slides
    const thirdGallerySlides = this.getGallerySlides(
      [
        pageContext.third_sub_header_first_picture_id,
        pageContext.third_sub_header_second_picture_id,
        pageContext.third_sub_header_third_picture_id,
        pageContext.third_sub_header_fourth_picture_id,
        pageContext.third_sub_header_fifth_picture_id,
      ],
      pageContext.third_sub_header_video_link,
    );

    // Forth gallery slides
    const forthGallerySlides = this.getGallerySlides(
      [
        pageContext.forth_sub_header_first_picture_id,
        pageContext.forth_sub_header_second_picture_id,
        pageContext.forth_sub_header_third_picture_id,
        pageContext.forth_sub_header_fourth_picture_id,
        pageContext.forth_sub_header_fifth_picture_id,
      ],
      pageContext.forth_sub_header_video_link,
    );

    // Fifth gallery slides
    const fifthGallerySlides = this.getGallerySlides(
      [
        pageContext.fifth_sub_header_first_picture_id,
        pageContext.fifth_sub_header_second_picture_id,
        pageContext.fifth_sub_header_third_picture_id,
        pageContext.fifth_sub_header_fourth_picture_id,
        pageContext.fifth_sub_header_fifth_picture_id,
      ],
      pageContext.fifth_sub_header_video_link,
    );

    // Sixth gallery slides
    const sixthGallerySlides = this.getGallerySlides(
      [
        pageContext.sixth_sub_header_first_picture_id,
        pageContext.sixth_sub_header_second_picture_id,
        pageContext.sixth_sub_header_third_picture_id,
        pageContext.sixth_sub_header_fourth_picture_id,
        pageContext.sixth_sub_header_fifth_picture_id,
      ],
      pageContext.sixth_sub_header_video_link,
    );

    // Seventh gallery slides
    const seventhGallerySlides = this.getGallerySlides(
      [
        pageContext.seventh_sub_header_first_picture_id,
        pageContext.seventh_sub_header_second_picture_id,
        pageContext.seventh_sub_header_third_picture_id,
        pageContext.seventh_sub_header_fourth_picture_id,
        pageContext.seventh_sub_header_fifth_picture_id,
      ],
      pageContext.seventh_sub_header_video_link,
    );

    // Eigth gallery slides
    const eigthGallerySlides = this.getGallerySlides(
      [
        pageContext.eigth_sub_header_first_picture_id,
        pageContext.eigth_sub_header_second_picture_id,
        pageContext.eigth_sub_header_third_picture_id,
        pageContext.eigth_sub_header_fourth_picture_id,
        pageContext.eigth_sub_header_fifth_picture_id,
      ],
      pageContext.eigth_sub_header_video_link,
    );

    // Activities
    const activityList = FORMAT_GRAPH_DATA(data.allActivityPages)
      .filter(item => item.addon_location_id === pageContext.addon_location_id);

    // Tours
    const tourList = FORMAT_GRAPH_DATA(data.allTourPages)
      .filter(item => item.destination_ids.includes(pageContext.api_id));

    // Other types
    const otherTypes = FORMAT_GRAPH_DATA(data.allDestinationPages)
      .filter(item => item.api_id !== pageContext.api_id && item.has_discover_more);

    return (
      <Layout page="destinations">
        <Helmet
          title={pageContext.page_title}
          meta={[
            { name: 'description', content: pageContext.meta_description },
            { name: 'og:title', content: pageContext.og_title },
            { name: 'og:description', content: pageContext.og_description },
          ]}
          link={[{ rel: 'canonical', href: `${pageContext.absolute_slug}/` }]}
        />

        <Banner
          modifier="l-destination-banner"
          backgroundImage={getImageUrl({
            id: pageContext.main_picture_id,
            images: data.allDestinationImages.edges,
            height: 590,
          })}
        >
          <Link to={PAGES.DESTINATIONS.PATH} className="l-destination-banner__view-all-link u-hide-screen-large-up">View all destinations</Link>
          <h2 className="c-heading c-heading--h0">
            {pageContext.name}
          </h2>
          <div className="c-button-container">

            {tourList.length
              ? (
                <button
                  type="button"
                  className="c-button c-button--primary c-button--wide"
                  onClick={() => scroller.scrollTo(TourList.name, SCROLL_CONFIG)}
                >
                  {`View tours via ${pageContext.name}`}
                </button>
              ) : null
            }

            {
              activityList.length
                ? (
                  <button
                    type="button"
                    className="c-button c-button--primary c-button--wide"
                    onClick={() => scroller.scrollTo(ActivityList.name, SCROLL_CONFIG)}
                  >
                    {`View ${pageContext.name} activities`}
                  </button>
                )
                : null
            }
          </div>
        </Banner>

        <section className="l-destination-page">
          <div className="l-container">
            <Breadcrumbs list={breadcrumbs} />

            {pageContext.small_map_id && (
              <div className="l-destination-page__map">
                <img
                  src={getImageUrl({
                    id: pageContext.small_map_id,
                    images: data.allDestinationImages.edges,
                    width: 350,
                    height: 350,
                  })}
                  alt={pageContext.name}
                />
              </div>
            )}

            <div className="l-destination-page__lead">
              <h1 className="c-heading c-heading--h2">
                {pageContext.h1_header}
              </h1>
              <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(pageContext.description_markdown) }} />
            </div>

            <div className="l-destination-page__sub l-destination-page__sub--first">
              <div>
                <Gallery
                  slides={firstGallerySlides}
                  showPagination
                />
              </div>
              <div>
                <h4 className="c-heading c-heading--h4">
                  {pageContext.first_sub_header}
                </h4>
                <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(pageContext.first_sub_header_description) }} />
              </div>
            </div>

            <div className="l-destination-page__sub l-destination-page__sub--second">
              <div>
                <h4 className="c-heading c-heading--h4">
                  {pageContext.second_sub_header}
                </h4>
                <p>
                  <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(pageContext.second_sub_header_description) }} />
                </p>
              </div>
              <div>
                <Gallery
                  slides={secondGallerySlides}
                  showPagination
                />
              </div>
            </div>

            {
              pageContext.third_sub_header ? (
                <div className="l-destination-page__sub l-destination-page__sub--first">
                  <div>
                    <Gallery
                      slides={thirdGallerySlides}
                      showPagination
                    />
                  </div>
                  <div>
                    <h4 className="c-heading c-heading--h4">
                      {pageContext.third_sub_header}
                    </h4>
                    <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(pageContext.third_sub_header_description) }} />
                  </div>
                </div>
              ) : null
            }

            {
              pageContext.forth_sub_header ? (
                <div className="l-destination-page__sub l-destination-page__sub--second">
                  <div>
                    <h4 className="c-heading c-heading--h4">
                      {pageContext.forth_sub_header}
                    </h4>
                    <p>
                      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(pageContext.forth_sub_header_description) }} />
                    </p>
                  </div>
                  <div>
                    <Gallery
                      slides={forthGallerySlides}
                      showPagination
                    />
                  </div>
                </div>
              ) : null
            }

            {
              pageContext.fifth_sub_header ? (
                <div className="l-destination-page__sub l-destination-page__sub--first">
                  <div>
                    <Gallery
                      slides={fifthGallerySlides}
                      showPagination
                    />
                  </div>
                  <div>
                    <h4 className="c-heading c-heading--h4">
                      {pageContext.fifth_sub_header}
                    </h4>
                    <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(pageContext.fifth_sub_header_description) }} />
                  </div>
                </div>
              ) : null
            }

            {
              pageContext.sixth_sub_header ? (
                <div className="l-destination-page__sub l-destination-page__sub--second">
                  <div>
                    <h4 className="c-heading c-heading--h4">
                      {pageContext.sixth_sub_header}
                    </h4>
                    <p>
                      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(pageContext.sixth_sub_header_description) }} />
                    </p>
                  </div>
                  <div>
                    <Gallery
                      slides={sixthGallerySlides}
                      showPagination
                    />
                  </div>
                </div>
              ) : null
            }

            {
              pageContext.seventh_sub_header ? (
                <div className="l-destination-page__sub l-destination-page__sub--first">
                  <div>
                    <Gallery
                      slides={seventhGallerySlides}
                      showPagination
                    />
                  </div>
                  <div>
                    <h4 className="c-heading c-heading--h4">
                      {pageContext.seventh_sub_header}
                    </h4>
                    <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(pageContext.seventh_sub_header_description) }} />
                  </div>
                </div>
              ) : null
            }

            {
              pageContext.eigth_sub_header ? (
                <div className="l-destination-page__sub l-destination-page__sub--second">
                  <div>
                    <h4 className="c-heading c-heading--h4">
                      {pageContext.eigth_sub_header}
                    </h4>
                    <p>
                      <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(pageContext.eigth_sub_header_description) }} />
                    </p>
                  </div>
                  <div>
                    <Gallery
                      slides={eigthGallerySlides}
                      showPagination
                    />
                  </div>
                </div>
              ) : null
            }

          </div>
        </section>

        {!!activityList.length && (
          <ActivityList
            title={`Our top ${pageContext.name} activities`}
            subTitle="Copy describing what activities are and how they work."
            list={activityList}
            limit={8}
            showFooter
          />
        )}

        {!!tourList.length && (
          <TourList
            heading={<h2 className="c-heading c-heading--h2">{`Tours including ${pageContext.name}`}</h2>}
            list={tourList}
            modifier="u-background--white"
            hideFilters
            hideFooter
          />
        )}

        <InstagramList
          title={pageContext.crowdriff_header}
          subTitle="Want to be featured here? Simply tag your Insta pics with #hakatours or upload your pictures <a href='https://upload.crowdriff.com/haka-tours-guest-images' target='_blank'>here</a>."
          hash={pageContext.crowdriff_code}
        />

        {/* <BlogList */}
        {/* title={`Articles about ${pageContext.name}`} */}
        {/* list={[1, 2]} */}
        {/* /> */}

        <OtherTypes list={otherTypes} title="Other Destinations" />

        <FooterBanner />
      </Layout>
    );
  }
}

DestinationPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const query = graphql`
  query {
    allTourPages(
      sort: {fields: [order], order: ASC},
      filter: {
                hidden_category: { eq: false },
                archived: { eq: false },
              }
    ) {
      edges {
        node {
          id
          api_id
          name
          slug
          number_of_days
          starting_price
          short_description_markdown
          parent_name
          absolute_slug
          destination_ids
          category_ids
          picture_id
          small_map_id
          sliding_first_picture_id
          sliding_second_picture_id
          sliding_third_picture_id
          sliding_fourth_picture_id
          sliding_fifth_picture_id
          sliding_sixth_picture_id
          sliding_seventh_picture_id
          sliding_eighth_picture_id
          sliding_ninth_picture_id
          sliding_tenth_picture_id
          background_color
          destination_ids
          parent_category_icon {
            id
            svg_text
          }
        }
      }
    }

    allActivityPages(
      filter: {
        guide_app_only: { ne: true },
      }
    ) {
      edges {
        node {
          id
          api_id
          name
          location
          absolute_slug
          sliding_first_picture_id
          addon_location_id
          options {
            price
            archived
            seasonal_pricings {
              id
              price
              available_from
              available_to
              hidden
            }
          }
        }
      }
    }

    allDestinationPages {
      edges {
        node {
          id
          api_id
          name
          absolute_slug
          has_discover_more
        }
      }
    }

    allDestinationImages {
      edges {
        node {
          api_id
          imgix_url
        }
      }
    }
  }
`;

export default DestinationPage;
